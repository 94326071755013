import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import { css } from 'styled-components/macro'; //eslint-disable-line
import { HashLink } from 'react-router-hash-link';

import Header, {
  NavLink,
  NavLinks,
  PrimaryLink,
  LogoLink,
  NavToggle,
  DesktopNavLinks,
} from 'components/headers/light.js';
import ResponsiveVideoEmbed from '../../helpers/ResponsiveVideoEmbed.js';

const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100`}
  }
  ${NavLink} {
    ${tw`hover:text-primary-500`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;
// const Container = styled.div`
//   ${tw`relative -mx-8 -mt-8 bg-center bg-cover`}
//   background-image: url("https://images.unsplash.com/photo-1522071901873-411886a10004?ixlib=rb-1.2.1&auto=format&fit=crop&w=1920&q=80");
// `;

// TEST
const Container = styled.div`
  ${tw`relative -mx-8 -mt-10 bg-center bg-cover`}
  background-image: url("https://images.unsplash.com/photo-1542744173-8e7e53415bb0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80");
  background-attachment: fixed;
`;

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-gray-900 opacity-75`;

const HeroContainer = tw.div`z-20 relative mx-auto py-20`;
const TwoColumn = tw.div`pt-24 pb-32 px-4 flex justify-between items-center flex-col lg:flex-row`;
const LeftColumn = tw.div`lg:mx-20 flex flex-col items-center lg:block`;
const RightColumn = tw.div`w-full sm:w-5/6 lg:w-1/2 mt-16 lg:mt-0 lg:pl-8`;

const Heading = styled.h1`
  ${tw`text-3xl text-center lg:text-left sm:text-4xl lg:text-5xl xl:text-6xl font-medium text-gray-100 leading-none`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const Content = styled.h1`
  ${tw`text-xl text-center lg:text-left font-medium text-gray-100 leading-8 tracking-wide`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const SlantedBackground = styled.span`
  ${tw`px-4 -mx-4 py-2`}
  &::before {
    content: '';
    ${tw`absolute inset-0  transform -skew-x-12 -z-10`}
  }
`;

const Notification = tw.span`inline-block my-4 pl-3 py-1 text-gray-100 border-l-4 border-blue-500 font-medium text-sm`;

const PrimaryAction = tw.button`px-8 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 bg-gray-100 text-primary-500 font-bold rounded shadow transition duration-300 hocus:bg-primary-500 hocus:text-gray-100 focus:shadow-outline`;

const StyledResponsiveVideoEmbed = styled(ResponsiveVideoEmbed)`
  padding-bottom: 56.25% !important;
  padding-top: 0px !important;
  ${tw`rounded`}
  iframe {
    ${tw`rounded bg-black shadow-xl`}
  }
`;

const BackgroundAsImage = () => {
  const navLinks = [
    <NavLinks key={1}>
      <HashLink to='/#'>
        <NavLink>Home</NavLink>
      </HashLink>
      <HashLink to='/#about'>
        <NavLink>About</NavLink>
      </HashLink>
      <HashLink to='/#services'>
        <NavLink>Services</NavLink>
      </HashLink>
      <HashLink to='/#expertise'>
        <NavLink>Expertise</NavLink>
      </HashLink>
      <HashLink to='/#contact-us'>
        <NavLink>Contact Us</NavLink>
      </HashLink>
      {/* <NavLink href='/#portfolio'>Portfolio</NavLink> */}
      {/* <NavLink href='/#'>Home</NavLink>
      <NavLink href='/#about'>About</NavLink>
      <NavLink href='/#services'>Services</NavLink>
      <NavLink href='/#expertise'>Expertise</NavLink>
      <NavLink href='/#contact-us'>Contact Us</NavLink> */}
    </NavLinks>,
    // <NavLinks key={2}>
    //   <PrimaryLink href='/#'>Hire Us</PrimaryLink>
    // </NavLinks>,
  ];

  return (
    <Container>
      <OpacityOverlay />
      <HeroContainer>
        <StyledHeader links={navLinks} />
        <TwoColumn>
          <LeftColumn>
            {/* <Notification>We have now launched operations in Europe.</Notification> */}
            <Heading>
              <span>Welcome to</span>
              <br />
              <SlantedBackground>
                <span tw='font-black'>Codelabs</span> <span tw='text-primary-500 font-medium'>Infosoft</span>
              </SlantedBackground>
            </Heading>
            <br />
            <Content>
              {/* We are a team of talented and solution-oriented, providing services for Microservices, IOS, Android,
              Automotive, AWS, React.JS, Node.JS, Automation, Managed Services, and Consulting. */}
              We provide global technology consulting and IT services company. We indulge in providing services for
              Microservices, IOS, Android, AWS, React.JS, Node.JS, and Managed Services.
            </Content>
            <HashLink to='/#about'>
              <PrimaryAction>Get Started</PrimaryAction>
            </HashLink>
          </LeftColumn>
          <RightColumn>
            {/* <StyledResponsiveVideoEmbed
              url='//player.vimeo.com/video/374265101?title=0&portrait=0&byline=0&autoplay=0&responsive=1'
              background='transparent'
            /> */}
          </RightColumn>
        </TwoColumn>
      </HeroContainer>
    </Container>
  );
};

export default BackgroundAsImage;
