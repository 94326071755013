import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
//eslint-disable-next-line
import { css } from 'styled-components/macro';
import { SectionHeading } from 'components/misc/Headings.js';

import defaultCardImage from '../../images/shield-icon.svg';

import { ReactComponent as SvgDecoratorBlob3 } from '../../images/svg-decorator-blob-3.svg';

// import SupportIconImage from '../../images/support-icon.svg';
// import ShieldIconImage from '../../images/shield-icon.svg';
// import CustomizeIconImage from '../../images/customize-icon.svg';
// import FastIconImage from '../../images/fast-icon.svg';
// import ReliableIconImage from '../../images/reliable-icon.svg';
// import SimpleIconImage from '../../images/simple-icon.svg';

import ConsultingIcon from 'images/codelabsImages/services_simple-icon.svg';
import ManagedServicesIcon from 'images/codelabsImages/whatwedo_customize-icon.svg';
import DevOpsServicesIcon from 'images/codelabsImages/services_reliable-icon.svg';

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
`;
const Heading = tw(SectionHeading)`w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 px-6 flex`}
`;

const Card = styled.div`
  ${tw`flex flex-col mx-auto max-w-xs items-center px-6 py-10 border-2 border-dashed border-secondary-100 rounded-lg mt-12`}
  .imageContainer {
    ${tw`text-center rounded-full p-4 bg-gray-200`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`mt-4 font-bold text-xl leading-none`}
  }

  .description {
    ${tw`mt-3 font-semibold text-secondary-100 text-sm leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

const DashedBorderSixFeatures = () => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const cards = [
    {
      imageSrc: ConsultingIcon,
      // title: 'Consulting Services',
      title: 'Recruitment Process Outsourcing',
      // description:  'Our RPO service provides our clients with the power to rapidly acquire high-performing staff, optimize their workforce, and reduce operational costs and risks, whilst accessing excellent technology and recruitment expertise. High-performing businesses need to build a competitive organizational capability. Competition for the best talent and knowledge is increasing, not declining. RPO is a vital component within the HR strategies of today’s leading companies.',
      description:
        'At Codelabs we provide RPO services to our clients to empower the requirements. In the competitive market, companies must be more agile and proficient in finding resources and we help them here. We understand the need and provide solutions at optimal cost with zero compliance risks. We drive the entire recruitment process in a cost-effective way which should also meet quality and scalability features. Our workforce management stack includes Direct Hire, Temp-to-hire, Consulting, Temporary, and Payrolling.',
    },
    {
      imageSrc: ManagedServicesIcon,
      title: 'Managed Services',
      // description: "We will follow managed services like Providing more capability to your company end-users, Allowing in-house IT to focus on more strategic IT programs, Free your team to focus on your business's core competencies, and MSPs usually price their services on a subscription basis. Based on the services selected, the pricing is usually scoped on the number of devices with pricing aligned to packages across a range of categories. Some provide customer support onsite when required. Basic services often start with a monitoring service, which identifies potential issues, which you resolve on your own.",
      description:
        'We take responsibility to manage and control and coordinate networks for business so that these businesses can focus on their core activities and be worry-free about any disturbances. The pricing usually works based on the services selected varying across categories. We also provide customer support at the client premises if required. We deliver remote infrastructure management, a complete monitoring services and also continue to invest in multiple initiatives that help us constantly innovate and deliver differentiated and incremental value to our customers.',
    },
    {
      imageSrc: DevOpsServicesIcon,
      title: 'DevOps Services',
      // description: 'Our DevOps developers build DevOps applications for large-scale enterprises that are scalable, reliable & secure at the same time. Consulting services covering containerization, server support, server security, orchestration & Virtualization. We will support Continuous Software Development, Integration, Testing, Deployment, and Monitoring. Also AWS setup, Nginx, AWS lambda, Load Balance creation, Docker and Kubernetes setup, CI/CD setup, scalable and security.',
      description:
        'Our engineers are well versed in building scalable applications for a large-scale enterprise. We do take care of the following activities monitoring. AWS setup, Nginx, AWS lambda, Load Balance creation, Docker  and  Kubernetes setup, CI/CD setup, scalable and security Consulting services covering containerization, server support, server security, orchestration & Virtualization. We will support continuous software development, integration, and testing until deployment.',
    },
    // {
    //   imageSrc: ShieldIconImage,
    //   title: 'Ads Management',
    //   description:
    //     'We create and manage ads that you need, from creation to deployment. Lorem ipsum donor sit amet consicou.',
    // },
    // { imageSrc: SupportIconImage, title: 'Video Marketing' },
    // { imageSrc: FastIconImage, title: 'PR Campaign' },
  ];

  return (
    <Container id='services'>
      <ThreeColumnContainer>
        <Heading>
          Our Professional <span tw='text-primary-500'>Services</span>
        </Heading>
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className='imageContainer'>
                <img src={card.imageSrc || defaultCardImage} alt='' />
              </span>
              <span className='textContainer'>
                <span className='title'>{card.title || 'Fully Secure'}</span>
                <p className='description'>
                  {card.description ||
                    'Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud. Sic Semper Tyrannis. Neoas Calie artel.'}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};

export default DashedBorderSixFeatures;
