import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import { HashLink } from 'react-router-hash-link';
// import { Link } from 'react-router-dom';

import { Container as ContainerBase } from 'components/misc/Layouts.js';
import logo from '../../images/codelabsImages/codelabs_logo_final_wa.png';
import { ReactComponent as FacebookIcon } from '../../images/facebook-icon.svg';
// import { ReactComponent as TwitterIcon } from '../../images/twitter-icon.svg';
// import { ReactComponent as YoutubeIcon } from '../../images/youtube-icon.svg';

const Container = tw(ContainerBase)`bg-gray-900 text-gray-100 -mx-8 -mb-8`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const Row = tw.div`flex items-center justify-center flex-col px-8`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-8`;
const LogoTextFirst = tw.h5`ml-2 text-2xl font-black tracking-wider`;
const LogoTextSecond = tw.h5`ml-2 text-2xl font-medium text-primary-500 tracking-wider`;

const LinksContainer = tw.div`mt-8 font-medium flex flex-wrap justify-center items-center flex-col sm:flex-row`;
const StyledLink = tw.a`border-b-2 border-transparent hocus:text-primary-300 hocus:border-primary-300 pb-1 transition duration-300 mt-2 mx-4`;

const SocialLinksContainer = tw.div`mt-8`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block text-gray-100 hover:text-gray-500 transition duration-300 mx-4`}
  svg {
    ${tw`w-5 h-5`}
  }
`;

const CopyrightText = tw.p`text-center mt-5 font-medium tracking-wide text-sm text-gray-600`;

const SupportContainer = tw.div`flex justify-end mt-10 -mb-16 mr-10`;
const SupportText = tw.p`text-sm text-gray-600 font-medium tracking-wide hover:text-primary-300`;

const MiniCenteredFooter = () => {
  return (
    <Container>
      <Content>
        <Row>
          <LogoContainer>
            <LogoImg src={logo} />
            <LogoTextFirst>Codelabs</LogoTextFirst>
            <LogoTextSecond>Infosoft</LogoTextSecond>
          </LogoContainer>
          <LinksContainer>
            <HashLink to='/#'>
              <StyledLink>Home</StyledLink>
            </HashLink>
            <HashLink to='/#about'>
              <StyledLink>About</StyledLink>
            </HashLink>
            <HashLink to='/#services'>
              <StyledLink>Services</StyledLink>
            </HashLink>
            <HashLink to='/#expertise'>
              <StyledLink>Expertise</StyledLink>
            </HashLink>
            <HashLink to='/#contact-us'>
              <StyledLink>Contact Us</StyledLink>
            </HashLink>
          </LinksContainer>
          <SocialLinksContainer>
            <SocialLink target='_blank' href='https://www.facebook.com/Codelabsinfosoft.software.development/'>
              <FacebookIcon />
            </SocialLink>
            {/* <SocialLink target='_blank' href=''>
              <YoutubeIcon />
            </SocialLink>
            <SocialLink target='_blank' href=''>
              <TwitterIcon />
            </SocialLink> */}
          </SocialLinksContainer>
          <CopyrightText>&copy; Copyright 2022, Codelabs Infosoft Pvt Ltd. All Rights Reserved.</CopyrightText>
        </Row>
        <SupportContainer>
          <HashLink to='/privacy-policy' scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'start' })}>
            <SupportText>Privacy Policy</SupportText>
          </HashLink>
        </SupportContainer>
      </Content>
    </Container>
  );
};

export default MiniCenteredFooter;
