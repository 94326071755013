import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { css } from 'styled-components/macro'; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from 'components/misc/Headings.js';
import { SectionDescription } from 'components/misc/Typography.js';
import { Container, ContentWithPaddingXl } from 'components/misc/Layouts.js';
import { ReactComponent as ArrowRightIcon } from 'images/arrow-right-icon.svg';
import SupportIconImage from 'images/support-icon.svg';
import ShieldIconImage from 'images/shield-icon.svg';
// import CustomizeIconImage from 'images/customize-icon.svg';
import { ReactComponent as SvgDecoratorBlob3 } from 'images/svg-decorator-blob-3.svg';
// import FastIcon from 'images/fast-icon.svg';
import OutSourcingIcon from 'images/codelabsImages/whatwedo_fasticon.svg';
import ApplicationDevelopmentIcon from 'images/codelabsImages/whatwedo_customize-icon.svg';

const Heading = tw(SectionHeading)``;
const Subheading = tw(SubheadingBase)`text-center mb-3`;
const Description = tw(SectionDescription)`text-center mx-auto`;
const ThreeColumnContainer = styled.div`
  ${tw`mt-10 flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap lg:justify-center max-w-screen-lg mx-auto`}
`;
const Column = styled.div`
  ${tw`lg:w-1/3 max-w-xs mx-10`}
`;

const Card = styled.a`
  ${tw`mt-10 flex flex-col items-center text-center h-full mx-1 px-1 rounded transition-transform duration-300 transform hover:scale-105 `}
  .imageContainer {
    ${tw`text-center rounded-full p-4 bg-gray-200`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .title {
    ${tw`mt-4 font-bold text-xl leading-none`}
  }

  .description {
    ${tw`mt-4 text-sm font-medium text-secondary-300`}
  }

  .link {
    ${tw`mt-auto inline-flex items-center pt-5 text-sm font-bold text-primary-300 leading-none hocus:text-primary-900 transition duration-300`}
    .icon {
      ${tw`ml-2 w-4`}
    }
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-40`}
`;

const ThreeColSimple = ({
  cards = [
    {
      imageSrc: OutSourcingIcon,
      title: 'Outsourcing Service Provider',
      description:
        'Outsourcing is about managing relationship more than service-level agreements, and is a partnership, not a purchasing project. We, as a firm, have a vision to empower digital services across its ultimate clients. We are one of the best and leading outsourcing service providers by delivering the best results to our clients. We provide various services including Product Development, Application Development, Management Services, Consulting Services., etc. ',
      // url: 'https://timerse.com',
    },
    {
      imageSrc: ApplicationDevelopmentIcon,
      title: 'Application Development',
      description:
        'We provide various business opportunities outsourcing across India. We are an established team looking forward to providing exceptional results to our ultimate clients. We provide wholesome services including Full Stack Development, Cloud Infrastructure, Web Development, Python Development, UI/UX Development, Andriod and IOS Development, and Functional Consultants.',
      // url: 'https://google.com',
    },
  ],
  // linkText = 'Learn More',
  heading = 'What we do?',
  subheading = '',
  description = '',
  imageContainerCss = null,
  imageCss = null,
}) => {
  /*
   * This componets accepts a prop - `cards` which is an array of object denoting the cards. Each object in the cards array can have the following keys (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  4) url - the url that the card should goto on click
   */
  return (
    <Container>
      {/* <ContentWithPaddingXl> */}
      {subheading && <Subheading>{subheading}</Subheading>}
      {heading && <Heading>{heading}</Heading>}
      {description && <Description>{description}</Description>}
      <ThreeColumnContainer>
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className='imageContainer' css={imageContainerCss}>
                <img src={card.imageSrc} alt='' css={imageCss} />
              </span>
              <span className='title'>{card.title}</span>
              <p className='description'>{card.description}</p>
              {/* {linkText && (
                  <span className='link'>
                    <span>{linkText}</span>
                    <ArrowRightIcon className='icon' />
                  </span>
                )} */}
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      {/* </ContentWithPaddingXl> */}
      <DecoratorBlob />
    </Container>
  );
};

export default ThreeColSimple;
