import { useState } from 'react';
import { useAnimation, useCycle } from 'framer-motion';

//Below logic is for toggling the navbar when toggleNavbar is called. It is used on mobile toggling of navbar.
export default function useAnimatedNavToggler() {
  const [showNavLinks, setShowNavLinks] = useState(false);
  var [x, cycleX] = useCycle('0%', '150%');
  const animation = useAnimation();

  const toggleNavbar = (value) => {
    if (value === 'open') {
      setShowNavLinks(true);
      animation.start({ x: x, display: 'block' });
    } else if (value === 'close') {
      setShowNavLinks(false);
      animation.start({ x: x, display: 'none' });
    }
    // cycleX();
  };

  return { showNavLinks, animation, toggleNavbar };
}
