import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import {css} from 'styled-components/macro'; //eslint-disable-line
import {SectionHeading, Subheading as SubheadingBase} from 'components/misc/Headings.js';
import {PrimaryButton as PrimaryButtonBase} from 'components/misc/Buttons.js';
// import EmailIllustrationSrc from 'images/email-illustration.svg';
import ContactIllustrationicon from 'images/codelabsImages/contact_collab.svg';

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-2xl text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 mb-10 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col lg:flex-row`;
const Input = tw.input`border-2 px-5 py-3 rounded focus:outline-none font-medium transition duration-300 hocus:border-primary-500`;

const SubmitButton = tw(PrimaryButtonBase)`inline-block lg:ml-6 mt-6 lg:mt-0`;

const Text = tw.p`mt-2 font-black text-left text-lg text-center md:text-left leading-tight`;
const ContactLink = tw.a`hover:text-primary-300`;

const TwoColContactUsWithIllustration = ({
  subheading = 'Contact us',
  heading = (
    <>
      Feel free to <span tw='text-primary-500'>get in touch</span>
      <wbr /> with us.
    </>
  ),
  // description = "We're here to help and answer any question you might have. We look forward to hearing from you. 😄",
  description = "We're here to help and answer any question you might have. If you have any questions, concerns or issues, check below to find the details to get in touch with us. We look forward to hearing from you. 😄",
  // submitButtonText = 'Contact Me',
  // formAction = '#',
  // formMethod = 'get',
  textOnLeft = true
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  return (
    <Container id='contact-us'>
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={ContactIllustrationicon} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            <Description>{description}</Description>
            {/* <Form action={formAction} method={formMethod}>
              <Input type='email' name='email' placeholder='Your Email Address' />
              <SubmitButton type='submit'>{submitButtonText}</SubmitButton>
            </Form> */}
            <Text>
              Email: <ContactLink href='mailto:support@codelabsinfosoft.com'>support@codelabsinfosoft.com</ContactLink>
            </Text>
            <Text>
              Mobile: +91 - <ContactLink href='tel:+919538403073'>953 840 3073</ContactLink>
              {/* ,<ContactLink href='tel:+919912281227'>991 228 1227</ContactLink> */}
            </Text>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};

export default TwoColContactUsWithIllustration;
