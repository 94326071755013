import LandingPage from 'landingPage/LandingPage';
import PrivacyPolicy from 'privacy-policy/PrivacyPolicy';
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import 'style.css';
import 'tailwindcss/dist/base.css';

function App() {
  return (
    <>
      {/* <LandingPage /> */}
      <Routes>
        <Route path='/' element={<LandingPage />} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
      </Routes>
    </>
  );
}

export default App;
