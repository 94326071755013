import React, { useEffect } from 'react';
import { HashLink } from 'react-router-hash-link';
import AnimationRevealPage from 'helpers/AnimationRevealPage.js';
import { Container, ContentWithPaddingXl } from 'components/misc/Layouts';
import tw from 'twin.macro';
import styled from 'styled-components';
import { css } from 'styled-components/macro'; //eslint-disable-line
import Header, {
  NavLink,
  NavLinks,
  PrimaryLink,
  LogoLink,
  NavToggle,
  DesktopNavLinks,
} from 'components/headers/light.js';
import Footer from 'components/footers/MiniCenteredFooter.js';
// import Header from 'components/headers/light.js';
// import Footer from 'components/footers/FiveColumnWithInputForm.js';
import { SectionHeading } from 'components/misc/Headings';
import PrivacyPolicyData from './PrivacyPolicyData';

const StyledHeader = styled(Header)`
  ${tw`pt-8 -mt-2 max-w-none z-50`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100`}
  }
  ${NavLink} {
    ${tw`hover:text-primary-500`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;

const HeadingRow = tw.div``;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;

const PrivacyPolicy = ({ headingText = 'Privacy Policy' }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navLinks = [
    <NavLinks key={1}>
      <HashLink to='/#'>
        <NavLink>Home</NavLink>
      </HashLink>
      <HashLink to='/#about'>
        <NavLink>About</NavLink>
      </HashLink>
      <HashLink to='/#services'>
        <NavLink>Services</NavLink>
      </HashLink>
      <HashLink to='/#expertise'>
        <NavLink>Expertise</NavLink>
      </HashLink>
      <HashLink to='/#contact-us'>
        <NavLink>Contact Us</NavLink>
      </HashLink>
      {/* <NavLink href='/#'>Home</NavLink>
      <NavLink href='/#about'>About</NavLink>
      <NavLink href='/#services'>Services</NavLink>
      <NavLink href='/#expertise'>Expertise</NavLink>
      <NavLink href='/#contact-us'>Contact Us</NavLink> */}
    </NavLinks>,
  ];
  return (
    <>
      <StyledHeader links={navLinks} />
      <AnimationRevealPage disabled>
        {/* <Header /> */}
        <Container>
          <ContentWithPaddingXl>
            <HeadingRow>
              <Heading>{headingText}</Heading>
            </HeadingRow>
            <PrivacyPolicyData />
          </ContentWithPaddingXl>
        </Container>
        {/* <Footer /> */}
        <Footer />
      </AnimationRevealPage>
    </>
  );
};

export default PrivacyPolicy;
